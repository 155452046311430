import React from 'react';
import Link from 'gatsby-link';
import { Badge } from '@chakra-ui/core';
import styled from 'styled-components';

export const ContentBadges = ({ tags, categories }) => {
  return (
    <>
      <TagsContainer>
        {tags.map(tag => (
          <Link to={`/tag/${tag.replace(' ', '-').toLowerCase()}`}>
            <Badge variant="outline" m={2} fontSize="xs" variantColor="yellow">
              {tag}
            </Badge>
          </Link>
        ))}
      </TagsContainer>
      <TagsContainer>
        {categories.map(category => (
          <Link to={`/category/${category.replace(' ', '-').toLowerCase()}`}>
            <Badge variant="outline" m={2} fontSize="xs" variantColor="yellow">
              {category}
            </Badge>
          </Link>
        ))}
      </TagsContainer>
    </>
  );
};

const TagsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
`;
