import React from 'react';
import { graphql } from 'gatsby';
import { Section } from 'react-komponents';
import { Flex, Stack, Text } from '@chakra-ui/core';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import { PageLayout } from '../components/Layouts/layout';
import withI18next from '../i18n';
import { MDXComponents } from '../components/Layouts/mdxComponents';
import SEO, { TYPES } from '../components/seo';
import { ContentBadges } from '../components/ContentBadges/contentBadges.component';

const options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const BlogPost = ({ location, data: { post }, pageContext }) => {
  const metaTags = post.frontmatter.tags
    ? post.frontmatter.tags.map(tag => ({
        property: 'article:tag',
        content: tag,
      }))
    : [];
  const metaSections = post.frontmatter.categories
    ? post.frontmatter.categories.map(section => ({
        property: 'article:section',
        content: section,
      }))
    : [];
  const date = new Date(post.frontmatter.date).toLocaleDateString(
    'fr',
    options
  );

  return (
    <PageLayout lang={pageContext.langKey} location={location}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        location={location}
        lang={pageContext.langKey}
        imageUrl={
          post.frontmatter.thumbnail &&
          post.frontmatter.thumbnail.childImageSharp.original.src
        }
        imageWidth={
          post.frontmatter.thumbnail &&
          post.frontmatter.thumbnail.childImageSharp.original.width
        }
        imageHeight={
          post.frontmatter.thumbnail &&
          post.frontmatter.thumbnail.childImageSharp.original.height
        }
        date={post.frontmatter.date}
        meta={[
          ...metaTags,
          ...metaSections,
          {
            property: 'article:published_time',
            content: post.frontmatter.date,
          },
          {
            property: 'og:datePublished',
            content: post.frontmatter.date,
          },
        ]}
        type={TYPES.article}
      />
      <Section charlie>
        <Flex
          flex="1"
          align="center"
          direction="column"
          justify="center"
          mt={60}
        >
          <Stack maxWidth={800} width="100%">
            <Text fontSize="s">{date}</Text>
            <MDXProvider components={MDXComponents}>
              <MDXRenderer>{post.body}</MDXRenderer>
            </MDXProvider>
            <ContentBadges
              tags={post.frontmatter.tags || []}
              categories={post.frontmatter.categories || []}
            />
          </Stack>
        </Flex>
      </Section>
    </PageLayout>
  );
};

export const pageQuery = graphql`
  query projectQuery($slug: String!) {
    post: mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      excerpt
      timeToRead
      body
      frontmatter {
        title
        tags
        description
        categories
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
            original {
              src
              height
              width
            }
          }
        }
        date
        template
      }
    }
  }
`;

export default withI18next(BlogPost);
